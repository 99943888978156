import { createRouter, createWebHistory } from "vue-router";
import { $i18N } from "@/utility/util.js";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { i18n_title: "pgtitle.home" },
    component: () => import("@/pages/Home.vue"),
  },

  {
    path: "/airline-traffic",
    name: "Analytics Blogs",
    meta: { i18n_title: "pgtitle.analyticsblogs" },
    component: () => import("@/pages/Blogs/AnalyticsBlogsPage.vue"),
  },
  {
    path: "/time-series",
    name: "Time Series Blogs",
    meta: { i18n_title: "pgtitle.timeseriesblogs" },
    component: () => import("@/pages/Blogs/TimeSeriesBlogsPage.vue"),
  },
  {
    path: "/airport-traffic",
    name: "Airport Traffic Blogs",
    meta: { i18n_title: "pgtitle.airporttrafficblogs" },
    component: () => import("@/pages/Blogs/AirportTrafficBlogsPage.vue"),
  },
  {
    path: "/blog-posts",
    name: "Blog Posts",
    meta: { i18n_title: "pgtitle.allblogs" },
    component: () => import("@/pages/Blogs/AllBlogsPage.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    meta: { i18n_title: "pgtitle.contactus" },
    component: () => import("@/pages/ContactUs.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",
    meta: { i18n_title: "pgtitle.aboutus" },
    component: () => import("@/pages/AboutUs.vue"),
  },
  {
    path: "/sitemap",
    name: "SiteMap",
    meta: { i18n_title: "pgtitle.sitemap" },
    component: () => import("@/pages/SiteMapPage.vue"),
  },
  {
    path: "/careers",
    name: "Careers",
    meta: { i18n_title: "pgtitle.careers" },
    component: () => import("@/pages/CareersPage.vue"),
  },
  {
    path: "/business-service",
    name: "Business Service Page",
    meta: { i18n_title: "pgtitle.businessservicepage" },
    component: () => import("@/pages/Services/BusinessServicePage.vue"),
  },
  {
    path: "/enterprise-service",
    name: "Enterprise Service Page",
    meta: { i18n_title: "pgtitle.enterpriseservicepage" },
    component: () => import("@/pages/Services/EnterpriseServicePage.vue"),
  },
  {
    path: "/automation-service",
    name: "Automation Service Page",
    meta: { i18n_title: "pgtitle.automationservice" },
    component: () => import("@/pages/Services/AutomationServicePage.vue"),
  },
  {
    path: "/insights-reports",
    name: "Insights And Reports",
    meta: { i18n_title: "pgtitle.insightsreports" },
    component: () => import("@/pages/Services/InsightsReports.vue"),
  },
  {
    path: "/data-visualization",
    name: "Data Visualization",
    meta: { i18n_title: "pgtitle.datavisualization" },
    component: () => import("@/pages/Services/visualizationServicePage.vue"),
  },
  {
    path: "/artificial-intelligence",
    name: "Artificial Intelligence",
    meta: { i18n_title: "pgtitle.artificialintelligence" },
    component: () => import("@/pages/BlogPages/CommanBlogPages.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { i18n_title: "pgtitle.notfound" },
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to) => {
  let {
    meta: { i18n_title = "pgtitle.home" },
  } = to;
  document.title = $i18N(i18n_title);
});

export default router;
